.Div-MiInfoValor {
    user-select: none;
    position: relative;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    height: 60px;
    border: 1px solid rgb(23, 151, 10);
    border-radius: 4px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18pt;
    font-weight: bold;
    padding-top: 10px;
    color:red;
}
.MiInfoValor-Label {
    position: absolute;
    top: -25px;
    left: 4px;
    height: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 11pt;
}