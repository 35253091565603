.RowsDet {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 8px;
    margin-bottom: 20px;
}

.EtiqDet {
    width: 100%;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
}
.DatosCalc {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}
@media only screen and (max-width: 600px){
    .RowsDet {
        grid-template-columns: 100px 1fr;
    }
    .EtiqDet {
        font-size: 11px;
    }
}