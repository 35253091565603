.view-cuerpo {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 10000;
    display: grid;
    grid-template-rows: 50px 1fr;
}
.view-close {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    color: white;
    cursor: pointer;
}
.view-div {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    
}
.view-info {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.view-info-a {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}
.view-info-b {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
video {
    height: 100%;
    width: auto;
}

.btNext {
    position: absolute;
    right: 4px;
    top: 100px;
    height: 50px;
    width: 50px;
}

.btPrev {
    position: absolute;
    left: 2px;
    top: 100px;
    height: 50px;
    width: 50px;
}

@media only screen and (max-width: 600px){
    video {
        width: 100%;
        height: auto;
    }
}