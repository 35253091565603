.FondoNav {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50000;
}
.DivNav {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    max-width: 90%;;
    height: 550px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    padding: 150px 10px 10px 10px;
    overflow-y: auto;

}
.DivNavImg {
    position: absolute;
    top: 4px;
    height: 80px;
    width: 120px;
    left: calc(50% - 60px);
    border-radius: 4px;
}
.DivNavImg img {
    width: 100%;
    height: auto;
}
.rowsNav {
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 4px;
    margin-bottom: 10px;
}
.DivBot {
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
    margin-top: 20px;
}