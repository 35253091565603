.Fondo-Derecho {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.Div-Derecho {
    position: absolute;
    width: 600px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    padding: 20px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;

}

@media only screen and (max-width: 600px){
    .Div-Derecho {
        padding: 4px;
        font-size: 14pt;
    }
}