.Fondo-Foto {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 70010;
}

.Div-Foto {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90%;
    height: 350px;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 40px 1fr;
    min-height: 0;
}

#get_file, #get_file2 {
    opacity: 0;
}
.lblFotos {
    display: inline-block;
    line-height: 2.2em;
    padding: 0 0.62em;
    border: 1px solid #666;
    border-radius: 0.25em;
    background-image: linear-gradient( to bottom, #fff, #ccc );
    box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
    font-family: Tahoma;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}