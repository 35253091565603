.Cursos-div {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Cursos-marco {
    width: 250px;
    height: 150px;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
}
.Cursos-titulo {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: rgb(23, 151, 10);
    color: white;
    font-size: 18px;
    text-align: center;
    padding: 10px;
}
.Cursos-fecha {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
}
.Cursos-opcion {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 600px){
    .Cursos-marco {
        width: 400px;
        height: 200px;
    }
    .Cursos-titulo {
        font-size: 24px;
    }
    .Cursos-fecha {
        font-size: 14px;
    }

}
