.barra {
    width: 100%;
    height: 100%;
    background: rgb(23, 151, 10);
    /* background: linear-gradient(90deg, rgba(23, 151, 10,.9) 0%, rgba(23, 151, 10,0.7) 50%, rgba(23, 151, 10,0.4) 80%); */
    overflow-x: scroll;
    padding-left: 4px;
    padding-top: 4px;
    overflow-y: hidden;
    scrollbar-width: none;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
}
.barra-scroll {
    position: relative;
    width: 3000px;
    overflow-y: hidden;
    
}
.barra::-webkit-scrollbar {
    width: 0px;
}
.barra::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}