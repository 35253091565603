.Fondo-Lavado {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;

}

.Div-Lavado {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 90%;
    background-color: whitesmoke;
    border-radius: 4px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    min-height: 0;
    padding: 20px;
    overflow: auto;
}
.lavTitulo{
    position: relative;
    color: rgb(23, 151, 10);
    width: 100%;
    height: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.lavTitulo2 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 100px;
    column-gap: 10px;
}
.lavCols {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rowLab {
    display: grid;
    grid-template-columns: 40px 40px 1fr;
}

.rowLab2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rowLab3 {
    display: grid;
    grid-template-columns: 80px 1fr;
}
.rowLab4 {
    grid-template-columns: 120px 1fr;
}

.newRows{
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 4px;
    padding: 4px;
}
.newTitulo{
    position: absolute;
    color: rgb(23, 151, 10);
    top: -18px;
    background-color: whitesmoke;
    left: 2px;
    width: auto;
    padding: 4px;
    height: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}
.newLabel{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}
.newPeq {
    max-width: 200px;
}
.rowNewCab {
    position: relative;
}
.newVal {
    text-transform: uppercase;
}
.divBotGrabar {
    position: absolute;
    right: 2px;
    top: -15px;
    width: 150px;
    height: 100%;
}

@media only screen and (max-width: 600px){
    .lavTitulo {
        font-size: 14px;
    }
    .lavTitulo2 h5 {
        font-size: 10px;
    }
    .lavCols {
        grid-template-columns: 1fr;
        
    }
    .form-label {
        font-size: 10px;
    }
    .rowLab4 {
        grid-template-columns: 60px 1fr;
    }
}