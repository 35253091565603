.PDep-cuerpo {
    height: 100dvh;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.PDep-marco {
    width: 90%;
    height: 90%;
    max-width: 400px;
    max-height: 500px;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    display: grid;
    grid-template-rows: 100px 1fr 100px;
}
.PDep-header {
    position: relative;
    
}
.PDep-logo {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PDep-titulo {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: rgb(23, 151, 10);
    font-weight: bold;
    text-align: center;
}
.PDep-monto {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: rgb(23, 151, 10);
    font-weight: bold;
    text-align: center;
}
.PDep-nombre {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    color: red;
    text-align: center;
}
.PDep-body {
    overflow: auto;
    padding: 4px;
}
.PDep-footer {
    padding: 10px;
}