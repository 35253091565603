.Dialog-fondo {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;

}
#Div-dialogo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    display: grid;
    grid-template-rows: 30px 1fr;
}
#Dialog-titulo {
  background: rgb(16,138,51);
  background: linear-gradient(90deg, rgba(23, 151, 10,.9) 0%, rgba(23, 151, 10,0.7) 50%, rgba(23, 151, 10,0.4) 80%);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  text-align: center;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14pt;
  font-weight: bold;
  color: white;
  padding: 4px;
  text-shadow: 0 0 2px black;
}
#Dialog-contenido{
  position: relative;
  padding: 20px;
}
.boton-cerrar {
    position: absolute;
    right: -20px;
    top: -20px;
    height: 40px;
    width: 40px;
    border: 3px solid white;
    border-radius: 50%;
    background-color: rgb(23, 151, 10);
    color: white;
    cursor: pointer;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17pt;
    text-shadow: 0 0 2px black;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    z-index: 60001;
}


.fade-in-dialog {
  animation: fadeInDialog .5s;
}
@keyframes fadeInDialog {
  0% {opacity:0;width: 0%; height: 0%;}
  100% {opacity:1; width: 90%; height: 90%;}
}

.fade-out-dialog {
  animation: fadeOutDialog .5s;
}
@keyframes fadeOutDialog {
  0% {opacity:1;width: 90%; height: 90%;}
  100% {opacity:0; width: 0%; height: 0%;}
}

@media only screen and (max-width: 600px){
  #Dialog-titulo {
    font-size: 11pt;
    
  }
  #Dialog-contenido{
    padding: 4px;
  }
}