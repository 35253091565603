

.Vol-Cuerpo {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 230px 1fr 30px 80px;
    padding: 10px;
    min-height: 0;
}
.Vol-Cuerpo.encontrado {
    grid-template-rows: 60px 1fr 30px 80px;
}

.Vol-Footer {
    display: grid;
    grid-template-columns: 1fr 200px;
}
.Vol-Footer-Total {
    text-align: right;
    font-weight: bold;
}
.Vol-Footer-Grabar{
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
}
.Vol-Header {
    display: grid;
    grid-gap: 4px;
    grid-template-areas: "a_lab1 a_inp1 a_btbuscar a_btgrabar"
                         "a_lab2 a_inp2 a_btbuscar a_btgrabar"
                         "a_mens a_mens a_mens a_mens"
                         "a_lin a_lin a_lin a_lin";
    grid-template-columns: 50% 200px 200px 1fr;
    grid-template-rows: 50px 100px 1fr 2px;
    min-height: 0;
}
.Vol-Header.encontrado {
    grid-template-rows: 1px 1px 1fr 2px;
}
.a_lab1 {
    grid-area: a_lab1;
}
.a_lab2 {
    grid-area: a_lab2;
}
.a_inp1 {
    grid-area: a_inp1;
}
.a_inp2 {
    grid-area: a_inp2;
    display: grid;
    grid-template-columns: 0px 1fr 0px;
}
.a_btbuscar {
    grid-area: a_btbuscar;
}
.a_btgrabar {
    grid-area: a_btgrabar;
}
.a_mens {
    grid-area: a_mens;
}
.a_lin {
    border-bottom: 1px solid black;
    grid-area: a_lin;
}

.a_lab1.encontrado {display: none;}
.a_lab2.encontrado {display:none}
.a_btbuscar.encontrado{display:none}
.a_inp1.encontrado{display:none}
.a_inp2.encontrado{display:none}


.Vol-Filtro-Row1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
}

.Vol-Filtro-Labels {
    width: 100%;
    text-align: right;
}

.Vol-Filtro-Row2 {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 4px;
}
.divError {
    display: grid;
    grid-template-columns: 1fr 600px 1fr;
}

.Vol-Msg-Error{
    text-align: center;
    color: red;
    font-size: 16pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.oculto {
    display: none;
}

.Vol-Msg-Exito{
    text-align: center;
    color: rgb(23, 151, 10);
    font-size: 18pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.Vol-Datos {
    overflow-y: auto;
}


.Vol-Rows {
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
}

.oculto .lblFotos {
    display: none;
}
.Vol-Datos img {
    width: 400px;
    height: 400px;
}


.oculto img {
    display: none;
}

.oculto select {
    display:none;
}


@media only screen and (max-width: 600px){
    .Vol-Cuerpo {
        grid-template-rows: 300px 1fr 30px 70px ;
    }
    .Vol-Filtro-Labels {
        text-align: center;
    }
    .a_inp2 {
        grid-template-columns: 1fr 200px 1fr;
    }
    .Vol-Msg-Error {
        font-size: 10pt;
    }
    .Vol-Msg-Exito {
        font-size: 12pt;
    }
    .divError{
        grid-template-columns: 0px 1fr 0px;
    }
    .Vol-Header {
        grid-template-areas: "a_lab1 a_lab1 a_lab1 a_lab1"
                             "a_inp1 a_inp1 a_inp1 a_inp1"
                             "a_lab2 a_lab2 a_lab2 a_lab2"
                             "a_inp2 a_inp2 a_inp2 a_inp2"
                             "a_btbuscar a_btbuscar a_btbuscar a_btbuscar"
                             "a_btgrabar a_btgrabar a_btgrabar a_btgrabar"
                             "a_mens a_mens a_mens a_mens"
                             "a_lin a_lin a_lin a_lin";
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 30px 40px 30px 60px 70px  1px 70px 2px;
    }
}