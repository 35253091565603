.Fondo-Pregunta {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50000;
}

.Div-Pregunta {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90%;
    height: 350px;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 60px 100px 1fr;
    min-height: 0;
    padding: 10px;
}
.Div-Pregunta-Titulo {
  display: grid;
  grid-template-columns: 60px 1fr;
  color: red;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: bold;
}
.Div-Pregunta-Titulo-Icon {
  background-image: url("./../images/sec.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



.Div-Pregunta-Texto {
    text-align: center;
    color: black;
    padding: 20px 10px 10px 10px;
    font-size: 16pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.Div-PreguntaBoton {
    position: absolute;
    bottom: 2px;
    left: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px 1fr;

}


.fade-in-preg {
    animation: fadeInPreg .2s;
  }
  @keyframes fadeInPreg {
    0% {opacity:0;width: 0px; height: 0px;}
    100% {opacity:1; width: 500px; height: 300px;}
  }

  .fade-out-preg {
    animation: fadeOutPreg .2s;
  }
  @keyframes fadeOutPreg {
    0% {opacity:1;width: 500px; height: 300px;}
    100% {opacity:0; width: 0px; height: 0px;}
  }

  @media only screen and (max-width:600px) {
    .Div-Pregunta-Texto{
      font-size: 14pt;
    }
    .Div-Pregunta-Titulo {
      font-size: 18px;
    }
  }