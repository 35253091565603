.MiBoton {
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid rgb(23, 151, 10);
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14pt;
    font-weight: bold;
    background-color: rgb(23, 151, 10);
    color:white;
    letter-spacing: 2px;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
}
.MiBoton:hover{
    cursor: pointer;
    background-color: white;
    color: rgb(23, 151, 10);
    text-shadow: 0 0 1px black;
}