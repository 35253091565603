#menu_left_opciones {
    overflow-y: auto;
    background-color: rgb(23, 151, 10);
    color: white;
    font-weight: 300;

}

.anuncios{
    position: relative;
    left: 0px;
    width: 100%;
    height: 80px;
    min-height: 0px;
    display: grid;
    grid-template-columns: 80px 1fr;
    padding: 2px;
    cursor: pointer;
    
}
.anuncios_img {
    background-image: url("./../images/feriaxviii.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.anuncios_texto{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
    padding: 20px 4px 4px 4px;
}

.menu_opciones {
    position: relative;
    left: 0px;
    width: 100%;
    height: 40px;
    min-height: 0;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    font-size: 14px;
    max-height: 0;

}

.menu_padres {
    height: 60px;
    font-size: 18px;
    max-height: 60px;
    border-bottom: .5px solid white;
    margin-top: 10px;
}

.menu_opciones_icon {
    align-self: center;
    justify-self: center;
}

    .menu_opciones_icon img {
        width: 30px;
        height: auto;
        display: inline-block;
        filter: invert(1);
    }

.menu_opciones_texto {
    align-self: center;
    font-family: Tahoma, 'Segoe UI', Geneva, Verdana, sans-serif;
    /*padding-left: 20px;*/
    color: white;
    overflow: hidden;
    max-height: 0;
}
.menu_opciones_texto:hover{
    color:black;

}

.menu_opciones_mas {
    max-height: 0;
    overflow: hidden;
}

.menu_opciones.menu_padres .menu_opciones_texto {
    padding-left: 0;
    max-height: 60px;
}

.menu_opciones.menu_padres .menu_opciones_mas {
    max-height: 60px;
}

.menu_opciones.select {
    max-height: 60px;
}

.menu_opciones.visible {
    max-height: 60px;
}
.menu_opciones.visible .menu_opciones_texto {
    max-height: 60px;
}

.menu_opciones.visible .menu_opciones_mas {
    max-height: 60px;
}


.menu_opciones.actual {
    background-color: whitesmoke;
}

.menu_opciones.select .menu_opciones_texto {
    max-height: 60px;
}

.menu_opciones.select .menu_opciones_mas {
    max-height: 60px;
}

.menu_opciones_mas {
    align-self: center;
    justify-self: center;
    font-size: 16px;
    font-weight: 800;
    transition: all 200ms ease-in-out;
}

.menu_opciones.select .menu_opciones_mas {
    transform: rotate(90deg);
}

.nivel1 {
    padding-left: 20px;
}

.nivel2 {
    padding-left: 40px;
}

.nivel3 {
    padding-left: 60px;
    height: 30px;
}

.nivel4 {
    padding-left: 80px;
    height: 30px;
}

@media only screen and (max-width: 600px){
    .menu_opciones{
        height: 30px;
    }
    
    .menu_padres {
        height: 50px;
    }
}