.Div-Estado{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
}

@media only screen and (max-width: 600px){
    .Div-Estado{
        padding: 4px;
    }
}