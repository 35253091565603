.Maraton-cuerpo {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Maraton-marco {
    width: 90%;
    height: 90%;
    max-width: 600px;/* 
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px; */
    display: grid;
    grid-template-columns: 6fr 4fr;
}
.div-imagen {
    position: relative;
    display: flex;
    justify-content: center;
}
.div-imagen img {
    width: 100%;
    height: auto;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
}
.Maraton-marco-textos {
    padding: 4px;
}
.Maraton-marco-texto1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.Maraton-marco-texto2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
}
.Maraton-marco-boton {
    display: flex;
    justify-content: center;
}
.Maraton-marco-inscrito {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}
.Maraton-info {
    text-align: center;
    font-weight: bold;
    font-size: 11px;
}

@media only screen and (max-width: 480px) {
    .Maraton-marco {
        max-width: 300px;
        grid-template-columns: 1fr;
        grid-template-rows: 60px 1fr;
    }
    .div-imagen img {
        height: 60px;
        width: auto;
    }
    .Maraton-marco-texto1 {
        font-size: 12px;
    }
    .Maraton-marco-texto2 {
        font-size: 10px;
    }
}