.camp-cuerpo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1200px;
  max-width: 90%;
  height: 600px;
  max-height: 90%;
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  display: flex;
  flex-direction: column;
}
.camp-titulo {
  background-color: rgb(23, 151, 10);
  position: relative;
  width: 100%;
  height: 40px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.camp-mensaje-a {
    position: relative;
    width: 100%;
    height: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    color:rgb(23, 151, 10);
}
.camp-mensaje {
    position: relative;
    height: 120px;
    width: 100%;
    text-align: center;
    color:rgb(23, 151, 10);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    border-bottom: 1px solid rgb(23, 151, 10);
    display: none;
    overflow: auto;
}
.camp-body {
  flex-grow: 1;
  overflow: auto;
  padding: 4px;
}
.camp-footer {
    position: relative;
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
}
.camp-rows {
  position: relative;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-areas: "a_num a_nombre a_nacimiento a_sexo a_size a_enfermedad a_alergia";
  grid-template-columns: 30px minmax(300px, 25%) minmax(200px, 15%) minmax(200px, 15%) minmax(200px, 15%) minmax(200px, 15%) minmax(200px, 15%);
  column-gap: 2px;
  border-bottom: 1px solid rgb(23, 151, 10);
  padding-top: 2px;
}
.camp-rows div {
  display: flex;
  align-items: center;
}
.camp-row-id {
  grid-area: a_num;
  font-size: 10px;
  font-weight: bold;
  justify-content: center;
  color:rgb(23, 151, 10);
}
.camp-row-nombre {
  grid-area: a_nombre;
}
.camp-row-nacimiento {
  grid-area: a_nacimiento;
}
.camp-row-sexo {
  grid-area: a_sexo;
}
.camp-row-size {
  grid-area: a_size;
}
.camp-row-enfermedad {
  grid-area: a_enfermedad;
}
.camp-row-alergia {
  grid-area: a_alergia;
}
.camp-rows.head div {
  font-weight: bold;
  justify-content: center;
  text-align: center;
}
@media only screen and (max-width: 480px) {
    .camp-rows.head {
        display: none;
    }
    .camp-row-id {
        font-size: 14px;
    }
    .camp-mensaje {
        display: block;
    }
  .camp-rows {
    height: 180px;
    grid-template-areas:
      "a_num a_nombre a_nombre"
      "a_num a_nacimiento a_nacimiento"
      "a_num a_sexo  a_size"
      "a_num a_enfermedad a_alergia";
    grid-template-columns: 30px 1fr 1fr;
    grid-template-rows: 40px 40px 40px 40px;
    row-gap: 2px;
    padding-top: 8px;
  }
  
}
