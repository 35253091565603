.Fondo-Pres {
    position: absolute;
    padding: 10px;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.RowPres {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin-bottom: 20px;
}
.RowPres2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin-bottom: 20px;
}
.RowPres2b {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 4px;
}

.Div-Etiq-Pres {
    align-self: center;
}

.EtiqPres {
    width: 100%;
    text-align: right;
}
    .EtiqPres.b {
        font-weight: bold;
    }
#cuotas {
    max-width: 200px;
}

.PresValores {
    max-width: 200px;
}

.Div-Error-Pres {
    color: red;
    font-size: 12px;
    padding-top: 20px;
}

@media only screen and (max-width: 600px) {
    .Div-Etiq-Pres {
        align-self: flex-start;
    }
    .RowPres {
        grid-template-columns: 100px 1fr;
        margin-bottom: 10px;
    }
    .RowPres2 {
        grid-template-columns: 100px 1fr;
        margin-bottom: 10px;
    }
    .RowPres2b {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    #montoCuota {
        font-size: 12px;
    }
}