.reel {
  position: relative;
  display: grid;
  grid-template-rows: 100px 20px;
  width: 100px;
  height: 150px;
  float: left;
  margin-right: 20px;
}
.reel-circle {
  position: relative;
  border: double 6px transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.reel-circle img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reel-titulo {
  text-align: center;
  color: white;
  font-size: 13px;
  overflow: hidden;
}

@media only screen and (max-width: 600px){
  .reel {
    grid-template-rows: 50px 30px;
    width: 50px;
    margin-right: 10px;
  }
  .reel-circle {
    width: 50px;
    height: 50px;
    border: double 2px transparent;
  }
  .reel-titulo {
    font-size: 8px;
  }
}