.RowsCC {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 4px;
}
.EtiqCC {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    text-align: right;
    padding-top: 6px;
    font-size: 18px;
}

.Div-Casas {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: grid;
    grid-template-rows: 40px 1fr;
}

.Div-Tabla{
    overflow-y: auto;
}

@media only screen and (max-width: 600px){
    .EtiqCC {
        font-size: 11px;
    }
}