#cuerpo-resumen {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    display: grid;
    grid-template-columns: 100px 1fr;
    height: 120px;
}
#div_logo {
    position: absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: lightgreen;
    opacity: .7;
}
    #div_logo.ahorros {
        background-color: lightgreen;
        background-image: url("./../images/ahorros.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    #div_logo.prestamos {
        background-color: red;
        background-image: url("./../images/prestamos.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    #div_logo.servicios {
        background-color: yellow;
        background-image: url("./../images/servicios.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    #div_logo.supermercado {
        background-color: lightblue;
        background-image: url("./../images/supermercado.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
    }
    #div_logo img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: auto;
    }

#div_resumen {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
#txt_monto  {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24pt;
    font-weight: bold;
    justify-self: center;
    align-self: center;
}
#texto {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12pt;
    justify-self: center;
}

@media only screen and (max-width: 600px) {
    #cuerpo-resumen {
        grid-template-columns: 3fr 7fr;
        height: 68px;
    }
    #div_logo {
        height: 30px;
        width: 30px;
    }
    #txt_monto {
        font-size: 11pt;
    }
    #texto {
        font-size: 7pt;
    }
}