.Fondo-WS {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50010;
}

.Div-WS {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    height: 450px;
    max-height: 90%;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 1fr 50px;
    min-height: 0;
    padding: 20px;
}
.Div-Tabla-Preguntas {
    overflow-y: auto;
}
.ws-logo {
    width: 100px;
    height: 71px;
    
}
.ws-celular {
    text-align: center;
}
.ws-tit {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18pt;
    text-align: center;
    font-weight: bold;
    color: rgb(23, 151, 10);
}
.ws-msg {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    font-size: 14pt;
    margin-bottom: 20px;
}

.WS-Boton {
    display: grid;
    grid-template-columns: 200px 1fr 200px;
}

.boton-cerrar-ws {
    position: absolute;
    right: -20px;
    top: -20px;
    height: 40px;
    width: 40px;
    border: 3px solid white;
    border-radius: 50%;
    background-color: rgb(23, 151, 10);
    color: white;
    cursor: pointer;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17pt;
    text-shadow: 0 0 2px black;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    z-index: 60001;
}

@media only screen and (max-width: 600px){
    .WS-Boton {
        grid-template-columns: 120px 1fr 120px;
    }
    .ws-tit {
        font-size: 14pt;
    }
}