#cuerpo-detallez {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    margin-bottom: 10px;
    height: calc(100% - 15px);

}
#cuerpo-detalle {
    overflow-y: auto;
}