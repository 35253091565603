.Fondo-Confirmacion {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 60000;
}

.Div-Confirmacion {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90%;
    height: 300px;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 30px 1fr;
}
.Div-Confirmacion-Titulo {
  background: rgb(23, 151, 10);
  background: linear-gradient(90deg, rgba(23, 151, 10,.9) 0%, rgba(23, 151, 10,0.7) 50%, rgba(23, 151, 10,0.4) 80%);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  color: white;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  text-shadow: 0 0 2px black;
}
.Div-Confirmacion-Texto {
    text-align: center;
    padding-top: 20px;
    font-size: 24pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.Div-Confirmacion-Boton {
    position: absolute;
    bottom: 2px;
    left: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px 50px 100px 1fr;

}


.fade-in-conf {
    animation: fadeInConf .2s;
  }
  @keyframes fadeInConf {
    0% {opacity:0;width: 0px; height: 0px;}
    100% {opacity:1; width: 500px; height: 300px;}
  }

  .fade-out-conf {
    animation: fadeOutConf .2s;
  }
  @keyframes fadeOutConf {
    0% {opacity:1;width: 500px; height: 300px;}
    100% {opacity:0; width: 0px; height: 0px;}
  }

  @media only screen and (max-width: 600px){
   .Div-Confirmacion-Texto{
     font-size: 18px;
   } 
  }