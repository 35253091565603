.Div-Fondo-Nuevo {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
}
.Div-Nuevo{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    max-width: 90%;
    background-color: whitesmoke;
    border-radius: 4px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    min-height: 0;
    padding: 10px;
    display: grid;
    grid-template-rows: 60px 1fr;
}
.Div-Nuevo-Datos{
    padding-top: 20px;
    overflow-y: auto;
}
.newGrupo{
    position: relative;
    width: 100%;
    border: 1px solid rgb(23, 151, 10);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
}
.newRows{
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 4px;
    padding: 4px;
}
.newTitulo{
    position: absolute;
    color: rgb(23, 151, 10);
    top: -18px;
    background-color: whitesmoke;
    left: 2px;
    width: auto;
    padding: 4px;
    height: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}
.newLabel{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
}
.newPeq {
    max-width: 200px;
}
.rowNewCab {
    position: relative;
}
.newVal {
    text-transform: uppercase;
}
.divBotGrabar {
    position: absolute;
    right: 2px;
    top: -15px;
    width: 150px;
    height: 100%;
}
.rowAyuda {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 4px;
}
.rowAyuda2 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 4px;
}
#ayudamutua {
    margin-right: 4px;
    font-size: 24px;
}
.rowLav {
    display: grid;
    grid-template-columns: 40px 40px 1fr;
}

.rowLav2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px){
    .newRows{
        grid-template-columns: 100px 1fr;
    }
    .rowAyuda {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        border-bottom: 1px solid rgb(23, 151, 10);
        margin-bottom: 10px;
    }
    .rowAyuda.titulo{
        display: none;
    }
    .rowAyuda2{
        grid-template-areas: "a_nom a_nom"
                             "a_ced a_fec"
                             "a_par a_par";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        border-bottom: 1px solid rgb(23, 151, 10);
        margin-bottom: 10px;
    }
    .div_ayNom {
        grid-area: a_nom;
    }
    .div_ayCed {
        grid-area: a_ced;
        
    }
    .div_ayNac {
        grid-area: a_fec;
        
    }
    .div_aypar {
        grid-area: a_par;
        
    }

    .rowAyuda2.titulo{
        font-weight: bold;
    }


}