.Fondo-Olvido {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50000;
}
.Div-Olvido {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 600px;
    max-width: 90%;;
    height: 400px;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    border-radius: 4px;
    border: 1px solid black;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    padding: 10px;
}

.RowOlv {
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 4px;
    margin-bottom: 50px;
}

#socio {
    text-transform:uppercase;
}

.fade-in-olv {
    animation: fadeInOlv .2s;
  }
  @keyframes fadeInOlv {
    0% {opacity:0;width: 0px; height: 0px;}
    100% {opacity:1; width: 600px; height: 400px;}
  }

  .fade-out-olv {
    animation: fadeOutOlv .2s;
  }
  @keyframes fadeOutOlv {
    0% {opacity:1;width: 600px; height: 400px;}
    100% {opacity:0; width: 0px; height: 0px;}
  }