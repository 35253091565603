.Fondo-Mensaje {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50010;
}

.Div-Pide {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    max-width: 90%;
    height: 400px;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 40px 1fr;
    min-height: 0;
}
.Div-Titulo {
  display: grid;
  grid-template-columns: 30px 1fr;
  background: rgb(23, 151, 10);
  background: linear-gradient(90deg, rgba(23, 151, 10,.9) 0%, rgba(23, 151, 10,0.7) 50%, rgba(23, 151, 10,0.4) 80%);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
  color: white;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  text-shadow: 0 0 2px black;
}
.Div-Titulo-Icon {
  background-image: url("./../images/info.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.Div-Mensaje.Error .Div-Titulo-Icon {
  background-image: url("./../images/error.png");
}
.Div-Edit {
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
}
.Div-Mensaje-Texto {
    text-align: center;
    color: red;
    padding: 20px 10px 10px 10px;
    font-size: 16pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    overflow-y: auto;
    margin-bottom: 40px;
}

.Div-Mensaje-Boton {
    position: absolute;
    bottom: 2px;
    left: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 100px 1fr;

}


.fade-in-pide {
    animation: fadeInPide .2s;
  }
  @keyframes fadeInPide {
    0% {opacity:0;width: 0px; height: 0px;}
    100% {opacity:1; width: 550px; height: 400px;}
  }

  .fade-out-pide {
    animation: fadeOutPide .2s;
  }
  @keyframes fadeOutPide {
    0% {opacity:1;width: 550px; height: 400px;}
    100% {opacity:0; width: 0px; height: 0px;}
  }

  @media only screen and (max-width:600px) {
    .Div-Mensaje-Texto{
      font-size: 14pt;
    }
    
  }