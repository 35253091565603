.Div-MiEdit {
    position: relative;
    margin-bottom: 20px;
}
.MiEdit {
    height: 60px;
    max-width: 200px;
    text-align: center;
    font-weight: bold;
    font-size: 18pt;
    font-family: Tahoma, Geneva, Verdana, sans-serif;
}

.MiEdit-Formato {
    position: absolute;
    bottom: -22px;
    left: 0px;
    width: 100%;
    max-width: 200px;
    height: 20px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    font-weight: bold;
}