.Div-Recargas{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.RecRows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin-bottom: 10px;

}
.EtiqRec {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12pt;
    width: 100%;
    text-align: right;
    height: 100%;


}
    .EtiqRec.b {
        font-weight: bold;
    }
.EtiqHist {
    font-weight: bold;
}
.RecRaya {
    border-bottom: 1px solid rgb(23, 151, 10);
    margin-bottom: 20px;
}
@media only screen and (max-width: 600px){
    .Div-Recargas{
        padding: 4px;
    }
    .RecRows{
        grid-template-columns: 120px 1fr;
    }
    .EtiqRec {
        font-size: 12px;
    }
}