.App {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 150px 1fr 40px;
  min-height: 0;
  overflow-y: auto;
}

.App-header {
  background: rgb(23, 151, 10);
  /* background: linear-gradient(90deg, rgba(23, 151, 10,.9) 0%, rgba(23, 151, 10,0.7) 50%, rgba(23, 151, 10,0.4) 80%); */
  display: grid;
  grid-template-areas: "h_left h_center h_right";
  grid-template-columns: 250px 1fr 250px;
  /* box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2); */
  min-height: 0;
  max-height: 60px;
  overflow: hidden;

}

#div_menu_user {
  position: absolute;
  top: 58px;
  right: 2px;
  width: 150px;
  height: 0px;
  background: rgb(23, 151, 10);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  z-index: 50000;
  text-align: center;
  transition: all 300ms ease-in-out;
  overflow-y: hidden;

}

#div_menu_user.abierto {
  height: 30px;
}

#div_menu_user a {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  cursor: pointer;
}

#bt_abre_menu {
  padding: 10px;
  cursor: pointer;
  grid-area: h_left;
}

#bt_abre_menu span {
  border-radius: 1px;
  display: block;
  width: 40px;
  height: 5px;
  background: white;
  margin: 6px 0px;
}

#App-header-titulo {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 25pt;
  color: white;
  text-shadow: 0 0 2px black;
  text-align: center;
  font-weight: bold;
  align-self: center;
  grid-area: h_center;
}

#App-header-right {
  display: grid;
  grid-template-columns: 1fr 62px;
  padding-right: 2px;
  background-color: transparent;
  min-height: 0;
  overflow: hidden;
  grid-area: h_right;
}

#div-saludo {
  text-align: right;
  padding-right: 10px;
  align-self: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

#div-foto {
  position: relative;
  cursor: pointer;
}

#img-foto {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

#App-body {
  padding: 20px 20px 20px 20px;
  display: grid;
  grid-template-rows: 200px 1fr;
  min-height: 0;

}

#App-body.hay_anuncio {
  /*  grid-template-rows: 250px 200px 1fr; hay un tema entre anuncios y sorteo */
  grid-template-rows: 350px 1fr;
}

.div_anuncio_stream {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 400px 1fr;
}

.div_anuncio_stream_blink {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(23, 151, 10);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  animation: blinker 1s linear infinite;
}

.div_anuncio_stream_nombre {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.boton-cerrar-stream {
  position: absolute;
  right: -20px;
  top: -20px;
  height: 40px;
  width: 40px;
  border: 3px solid white;
  border-radius: 50%;
  background-color: rgb(23, 151, 10);
  color: white;
  cursor: pointer;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17pt;
  text-shadow: 0 0 2px black;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  z-index: 60001;
}

.div_anuncio_stream img {
  width: auto;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.div_campamento {
  position: relative;
  gap: 10px;
  cursor: pointer;
}

.camp-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camp-reservado {
  position: absolute;
  top: 2px;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translateX(-50%);
  display: none;
}

.camp-reservado.reservado {
  display: block;
}

.camp-reservado img {
  width: 100%;
  height: auto;
}

.camp-img-img {
  height: 240px;
  width: auto;
  border-radius: 4px;
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

.camp-tit1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  text-align: center;
}

.camp-tit2 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.camp-tit3 {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.div_stream {
  display: grid;
  grid-template-rows: 1fr 100px;
  padding: 4px;
}

.div_streamb {
  position: relative;
}

.div_stream_botones {
  display: grid;
  grid-template-columns: 1fr 200px 200px 1fr;
}

#App-body iframe {
  width: 500px;
  height: 100%;
  margin: 0 auto;
  display: block;
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

#App-bodyz img {
  position: absolute;
  top: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  src: url("./images/logogrande.png");
  opacity: .9;
}

.menu_left {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 100%;
  background-color: white;

  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  transition: all 300ms ease-in-out;
  min-height: 0;
  display: grid;
  grid-template-rows: 60px 1fr;
  z-index: 500;
}
.menu-left-close {
  display: flex;
  justify-content: end;
}

.menu_left.oculto {
  left: -300px;
}

.menu_left_cabecera {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  min-height: 0;
  display: grid;
  grid-template-columns: 1fr 50px;
  padding: 4px;
}

.menu_left_cabecera img {
  height: 50px;
  width: auto;
}

#menu_left_cabecera_close {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

#div_sorteo {
  position: absolute;
  top: 350px;
  height: 80px;
  width: 400px;
  left: calc(50% - 200px);
  display: grid;
  grid-template-rows: 50px 1fr;
  cursor: pointer;
}

#div_sorteo .texto {

  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22pt;
  color: red;
  font-weight: bold;
  text-shadow: 0 0 1px black;
}

#div_sorteo_img {
  position: relative;
}

#div_sorteo img {
  height: 150px;
  width: auto;
}

#div_sorteo_participa {
  padding-top: 20px;
}

#div_sorteo .texto {
  font-size: 12pt;
}

#div_anuncios {
  /* position: absolute;
    top: 250px; */
  position: relative;
  height: 80px;
  width: 200px;
  left: calc(50% - 150px);
  cursor: pointer;
}

#div_anuncios .texto {
  position: absolute;
  left: 200px;
  top: 10px;
  width: 300px;
  height: 60px;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22pt;
  color: red;
  font-weight: bold;
  text-shadow: 0 0 1px black;
}

#div_anuncios img {
  width: 100%;
  height: auto;
}

#panel1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

#panel2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  min-height: 0;
}

#panel2a {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
}

#panel2b {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  min-height: 0;
}

.marcos {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  overflow-y: auto;
}


.misRows {
  display: grid;
  gap: 4px;
}

.misEtiquetas {
  width: 100%;
  text-align: right;
}

.misEtiquetas.grande {
  font-weight: bold;
  font-size: 14pt;
  margin-top: 10px;
}

.misEtiquetas.b {
  font-weight: bold;
}

.titDividendos {
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  margin-bottom: 10px;
}

.ExcMonto {
  font-size: 28px;
  font-weight: bold;
  color: red;
}

.quiereExcedente {
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: red;
}

.botDividendos {
  display: grid;
  grid-template-columns: 1fr 200px 1fr;
}

@media only screen and (max-width: 600px) {
  .App {
    grid-template-rows: 50px 80px 1fr 0px;
  }

  .camp-tit1 {
    font-size: 18px;
  }

  .App-header {
    grid-template-areas: "h_left  h_center h_right";
    grid-template-columns: 50px 1fr 50px;
  }

  #bt_abre_menu {
    padding: 4px;
  }

  #div_menu_user {
    top: 48px;
  }

  #App-header-titulo {
    font-size: 14pt;
  }

  #App-header-right {
    grid-template-columns: 0px 1fr;
  }

  #div-saludo {
    opacity: 0;
    font-size: 1px;
  }

  #App-body {
    overflow-y: auto;
    display: block;
    padding: 4px 4px 4px 4px;
  }

  #div_anuncios {
    height: 120px;
    width: 100px;
  }

  #div_anuncios .texto {
    left: 100px;
    font-size: 14pt;
    width: 200px;
    top: 15px;
  }

  #div_sorteo {
    top: 300px;
  }


  #div_sorteo img {
    float: right;
    height: 100px;
  }

  #div_sorteo_participa label {
    font-size: 14px;
  }

  #panel1 {
    grid-template-areas: "res_1 res_2"
      "res_3 res_4";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 70px 70px;
    gap: 4px;
    margin-bottom: 4px;
  }

  #res_1 {
    grid-area: res_1;
  }

  #res_2 {
    grid-area: res_2;
  }

  #res_3 {
    grid-area: res_3;
  }

  #res_4 {
    grid-area: res_4;
  }



  #panel2 {
    gap: 2px;
    grid-template-columns: 1fr;
    grid-template-areas: "a_graf"
      "a_dets";
    grid-template-rows: 200px 300px;
    gap: 4px;
  }

  #App-body.hay_anuncio #panel2 {
    margin-top: 200px;
  }

  #panel2a {
    grid-area: a_graf;
  }

  #panel2b {
    grid-area: a_dets;
    gap: 4px;
  }

  .marcos {
    padding: 4px;
  }

  #img-foto {
    width: 40px;
    height: 40px;
  }

  .menu_left {
    width: 400px;
    max-width: calc(100% - 50px);
  }

  .menu_left.oculto {
    left: -400px;
  }

  .div_anuncio_stream {
    grid-template-columns: 1fr 350px 1fr;
  }

  .div_stream_botones {
    grid-template-columns: 1fr 150px 150px 1fr;
  }

  .div_anuncio_stream_blink {
    font-size: 18px;
  }

  #App-body iframe {
    height: 100%;
    width: auto;
  }
}