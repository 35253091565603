.Div-ModAhorros {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
}

@media only screen and (max-width: 500px) {
    .Div-ModAhorros {
        padding: 4px;
    }
}