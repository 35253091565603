.Fondo-Wait {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100000;
}
.Div-Wait {
    position: absolute;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("./../images/wait.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}