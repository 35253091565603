.RowsCalc {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    margin-bottom: 20px;
}

.EtiqCalc {
    width: 100%;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
}
@media only screen and (max-width: 600px){
    .RowsCalc {
        grid-template-columns: 100px 1fr;
    }
    .EtiqCalc {
        font-size: 11px;
    }
}