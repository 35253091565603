.Fondo-Login {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;

}

.Div-Login {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 300px;
    max-width: 90%;
    background-color: whitesmoke;
    border-radius: 4px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    min-height: 0;
    display: grid;
    grid-template-columns: 3fr 7fr;
}
#bt_nuevo {
    position: absolute;
    bottom: -65px;
    left: 4px;
    height: 50px;
    width: 200px;
    border: 1px solid white;
    border-radius: 4px;
    background-color: rgb(23, 151, 10);
    color:white;
    text-align: center;
    vertical-align: middle;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
    
}
#div_login_logo {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 60px 1fr;
    padding-top: 60px;
}

#div_login_icon{
    position:absolute;
    top: -40px;
    left: calc(50% - 40px);
    height: 80px;
    width: 80px;
}

#div_login_icon img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

#div_login_datos{
    position: relative;
    padding-top: 60px;
    padding-right: 10px;
}

#div_login_olvido {
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 100%;
    height: 50px;
    font-size: 12px;
}
#div_login_olvido a {
    color: rgb(23, 151, 10);
}
#logo_login {
    width: 90px;
    height: auto;

}
    #div_login_olvido img {
        position: absolute;
        top: 8px;
        left: 0px;
        width: 20px;
        height: 20px;
        opacity: .9;
    }
    #a_login_olvido {
        position: absolute;
        top: 0px;
        left: 26px;
        width: calc(100% - 30px);
        text-align: left;
        padding-top: 8px;
    }

    @media only screen and (max-width: 600px) {
        #div_login_icon {
            top: -30px;
            left: calc(50% - 30px);
            height: 60px;
            width: 60px;
        }
        #div_login_logo {
            grid-template-columns: 1fr 60px 1fr;
        }
        #logo_login {
            width: 60px;
        }
    }