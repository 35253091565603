.misTablas {
    position: relative;
    display: grid;
    grid-template-rows: 30px 30px 1fr;
    min-height: 0;
    height: 100%;
}
.misTablasTitulo {
    text-align: center;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12pt;
    color: rgb(16,138,51);
}
.misTablasHeader {
    background-color: rgba(29, 186, 12,1);
    color:white;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.tabla_valores{
    text-align: right;
}
.misTablasBody {
    overflow-y: auto;
}
.misTablaRows {
    display: grid;
    gap: 4px;
    min-height: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 2px;
    border-bottom: 1px solid rgb(29, 186, 12, .5);
}
.misTablasReg {
    cursor: pointer;
}
.misTablasReg:hover {
    background-color: rgb(29, 186, 12, .4);
    font-weight: bold;
}
.misCols {
    align-self: center;
    padding: 2px;
}
    .misCols.izq{
        text-align: left;
    }
    .misCols.der{
        text-align: right;
    }
    .misCols.cen{
        text-align: center;
    }
    .misCols.b {
        font-weight: bold;
    }

@media only screen and (max-width: 600px) {
    .misTablas {
        grid-template-rows: 20px 20px 1fr;
    }
    .misTablasTitulo {
        font-size: 9pt;
    }
    .misTablasHeader{
        font-size: 10px;
    }
    .misTablaRows {
        font-size: 9px;
    }
}