.Fondo-Preguntas {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 50010;
}

.Div-Preguntas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 600px;
    max-height: 90%;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    grid-template-rows: 1fr 50px;
    min-height: 0;
    padding: 20px;
    overflow-y: auto;
}
.Div-Tabla-Preguntas {
    overflow-y: auto;
}

.Div-Boton {
    display: grid;
    grid-template-columns: 1fr 200px 1fr;
}