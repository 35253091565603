.Div-Ordenes {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    user-select: none;
    overflow-y: auto;

}
.rowOrd {
    display: grid;
    gap:4px;
    grid-template-columns: 1fr 1fr;
}
.rowOrd2 {
    display: grid;
    gap:4px;
    grid-template-columns: 130px 1fr;
}
.EtiqOrd {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    text-align: right;
    padding-top: 6px;
    font-size: 18px;
}
    .EtiqOrd.grande {
        font-weight: bold;
    font-size: 14pt;
    margin-top: 10px;
    }
    .EtiqOrd.b {
        font-weight: bold;
    }
    .datosCuotas {
        padding-top: 6px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
    }

@media only screen and (max-width: 600px){

    .rowOrd {
        grid-template-columns: 120px 1fr;
    }
    .rowOrd2 {
        grid-template-columns: 80px 1fr;
    }
    .EtiqOrd {
        font-size: 9px;

    }
    .EtiqOrd.grande {
        font-size: 11px;
    }
    .datosCuotas {
        font-size: 10px;
    }
}