.Fondo-Cambio {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 10001;

}
.Div-Cambio {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    max-width: 90%;
    height: 350px;
    background-color: white;
    border: 1px solid rgb(23, 151, 10);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
    border-radius: 4px;
    padding: 20px;
}
.Mens-Cambio  {
    position: relative;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    margin-bottom: 30px;
    color: red;
    border-bottom: 1px solid black;
}
.msg_fuerza {
    padding-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    color: red;
}
.msg_fuerza.verde {
    color: green;
}

.misRowsCC{
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 4px;
}
.divNewDat{
    display: grid;
    gap: 4px;
    grid-template-columns: 200px 1fr;
}
.divDatMensaje{
    color:red;
    font-size: small;
    align-self: center;
}
.misRowsCC2{
    display: grid;
    grid-template-columns: 200px 1fr;
}

.misEtiquetasCC{
    width: 100%;
    text-align: right;
}

@media only screen and (max-width: 600px){
    .divDatMensaje{
        align-self: flex-start;
    }
    .divNewDat{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .misRowsCC{
        grid-template-columns: 80px 1fr;
    }
    .misEtiquetasCC{
        font-size: 9px;
    }
    .misRowsCC2{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}