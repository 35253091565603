.Div-Estado{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
}

.RowFiltro{
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr 1fr;
}
.RowFiltro2{
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr 200px;
}
@media only screen and (max-width: 600px){
    .Div-Estado{
        padding: 4px;
    }
    .RowFiltro{
        grid-template-columns: 100px 1fr;
    }
    .RowFiltro2{
        grid-template-columns: 1fr;
    }
}